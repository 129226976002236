import React from "react"
import TickImage from "../../images/Vector.png"
import XImage from "../../images/X.png"

const TableBody = props => {
  console.log(props)
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          {props?.headerItem?.map((data, index) => {
            return (
              <th className="sub_header_section">
                <p className="percentage">{data.Percentage}</p>
                <p className="title_header">{data.Title}</p>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {props.item.map((data, index) => {
          return (
            <tr className={index % 2 === 0 ? "blur_row" : ""}>
              <td className="content_data">{data.Title}</td>
              <td>
                {data.Finder === true ? (
                  <img src={TickImage} alt="tickImage" />
                ) : (
                  <img className="cross-arrow" src={XImage} alt="tickImage" />
                )}
              </td>
              <td>
                {data.CollectionValue === true ? (
                  <img src={TickImage} alt="tickImage" />
                ) : (
                  <img className="cross-arrow" src={XImage} alt="tickImage" />
                )}
              </td>
              <td>
                {data.Managed === true ? (
                  <img src={TickImage} alt="tickImage" />
                ) : (
                  <img className="cross-arrow" src={XImage} alt="tickImage" />
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TableBody
