import React from "react"
import { Container } from "react-bootstrap"
import "./OurPackages.scss"
import HTMLReactParser from "html-react-parser"
import Table from "./Table"
import { useStaticQuery, graphql } from "gatsby"

const OurPackages = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        propertyManagements {
          Finder
          Managed
          Publish
          Sort
          Title
          CollectionValue
        }
      }
    }
  `)
  const tableData = data.glstrapi.propertyManagements

  const closeFunction = () => {
    props.handleFunction(false)
    window.scrollTo(0 ,4000);
  }

  return (
    <>
      {props.publish?
        <div  className="ourPackages_wrapper">
          <div className="title_content">
            {props.Tite_Content
              ? HTMLReactParser(props.Tite_Content)
              : null}
          </div>
          <div className="package_table">
            <Table theadData={props.List_Component} tbodyData={tableData} />
          </div>
          <div className="package-close-btn d-flex justify-content-center mt-4">
              <a className="block_btn btn btn-outline px-5" href="javascript:;" onClick={() => closeFunction()} >Close features</a>
          </div>
        </div>
      : "" }
    </>
  )
}

export default OurPackages
