import React from "react"
import { Container } from "react-bootstrap"
import "./LeadingPortals.scss"
import Slider from "react-slick"

const LeadingPortals = props => {

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="leading_portals_container">
      <Container className="portals_wrapper">
        <div className="title_content">
          <p>{props.Module.Title_Content.toUpperCase()}</p>
        </div>
        <div className="portal_slider">
          <Slider {...settings}>
            {props?.Module?.Portals?.map((item, index) => {
              return (
                <div>
                  <img src={item.Image.url} className="portal_logo" alt="img" />
                </div>
              )
            })}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default LeadingPortals
