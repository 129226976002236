import React, { useEffect, useState } from "react"
import ValuationPageBanner from "./ValuationStepsBanner/ValuationPageBanner"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap"
import Steps from "./Steps/Steps"
import GoogleReviewValuationStep from "./GoogleReviewValuationStep/GoogleReviewValuationStep"
import ValuationForm from "../../Components/forms/valuation-form"
import ReactMarkdown from "react-markdown/with-html"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import { navigate, useLocation } from "@reach/router"
import $ from "jquery"

const ValuationStepsComponent = props => {
  const GQLPage = props.GQLPage
  const location = useLocation();
  const GQLModules = props.GQLModules
  const ValuationBanner = GQLModules.filter(
    GQLModules => GQLModules.Banner_Image != null
  ).map(({ Banner_Image }) => ({ Banner_Image }))
  const myRef = React.createRef()
  const [Valuationform, setValuationform] = useState(false)
  const [valtype, setValtype] = useState("")

  const ShowValuationForm = valuationtype => {
    if(valuationtype === "Home_Visit_Valuation"){
      navigate("home")
    }else if(valuationtype === "Instant_Valuation"){
      navigate("instant")
    }else if(valuationtype === "Virtual_Valuation"){
      navigate("online")
    }
    setValuationform(true)
    setValtype(valuationtype)
    $("html, body").animate({ scrollTop: 0 }, 500)
  }

  const handleBack = () => {
    setValtype("")
    setValuationform(false)
    window.history.back()
    $("html, body").animate({ scrollTop: 0 }, 500)
  }

  useEffect(() => {

    if(location.pathname.includes("home")){
      setValtype("Home_Visit_Valuation")
      setValuationform(true)
    }else if(location.pathname.includes("instant")){
      setValtype("Instant_Valuation")
      setValuationform(true)
    }else if(location.pathname.includes("online")){
      setValtype("Virtual_Valuation")
      setValuationform(true)
    }

  }, [])
  
  return (
    <React.Fragment>
      <section
        className="valuation-main-landing valuation-steps-banner top-fix"
        style={{
          backgroundImage: `url(${ValuationBanner[0].Banner_Image.url})`,
        }}
      >
        <div ref={myRef} />
        <div className="valuation-inner-wrapper valuation-types">
          {Valuationform && (
            <div className="valuationstep1 valuationintrostep1">
              {valtype === "Instant_Valuation" ? (
                <ValuationPageBanner
                  Heading="Get a ballpark figure, instantly."
                  Title={GQLPage.Page_Caption}
                  Content="Get a rough idea of what your property is worth now. Machines haven’t replaced us yet, but they do an ok job of valuation estimates."
                  valtype={valtype}
                  handleBack={handleBack}
                />
              ) : valtype === "Home_Visit_Valuation" ? (
                <ValuationPageBanner
                  Heading="Find the value of your home."
                  Title={GQLPage.Page_Caption}
                  Content="Our local property expert will visit your property, allowing your home to live up to its true selling potential, ensuring you get the right price."
                  valtype={valtype}
                  handleBack={handleBack}
                />
              ) : (
                <ValuationPageBanner
                  Heading="Looking forward to e-meeting you."
                  Title={GQLPage.Page_Caption}
                  Content="We’ve just made it easier for you to meet with us and get all the expert advice you deserve when considering moving."
                  valtype={valtype}
                  handleBack={handleBack}
                />
              )}
            </div>
          )}
          <div className="valuationstep2 valuationintrostep2">
            <ValuationPageBanner
              Heading="Let’s get to know each other”"
              Title="Step 1 / 2"
              Content="&nbsp;"
            />
          </div>
          <div className="valuationstep3 valuationintrostep3">
            <ValuationPageBanner
              Heading="Thanks Chris. Now your preferred time and date."
              Title="Step 2 / 2"
              Content="&nbsp;"
            />
          </div>
          <div className="valuationthankyou valuationintrothankyou">
            <ValuationPageBanner
              Heading="Thanks! We can't wait to meet you."
              Title="Valuation Confirmation"
              Content="&nbsp;"
            />
          </div>
          <div className="instantvaluationsection instantvaluationintro">
            <ValuationPageBanner
              Heading="Thanks. Here are your property's estimated sale and rental values."
              Title="Sale and Rental Values"
              Content="&nbsp;"
            />
          </div>
          <div className="dateandtimesection dateandtimesectionintro">
            <ValuationPageBanner
              Heading="Thanks for requesting a home visit valuation. When are you available?."
              Title="Date and time selection"
              Content="&nbsp;"
            />
          </div>

          {!Valuationform &&
            GQLModules.map((Modules, i) => {
              return (
                <>
                  {/* Heading with CTA Module */}
                  {Modules.__typename ===
                    "GLSTRAPI_ComponentComponentsIntro" && (
                    <div className="defaultvaluationintro">
                      <ValuationPageBanner
                        Heading={Modules.Intro_Heading}
                        Title={GQLPage.Page_Caption}
                        Content={Modules.Intro_Content}
                      />
                    </div>
                  )}

                  {Modules.__typename ===
                    "GLSTRAPI_ComponentComponentsValuationTileBlocks" &&
                    !Valuationform && (
                      <ScrollAnimation
                        animateOnce={true}
                        duration={0.5}
                        animateIn="fadeInUp"
                      >
                        <Container className="val-box-wrapper">
                          <Row>
                            {Modules.Add_Valuation_Tile.map((box, index) => {
                              return (
                                <>
                                  <Col
                                    xl={4}
                                    sm={4}
                                    className="tab-box card-box"
                                  >
                                    <div className="val-boxes cardbox-content valuation-block">
                                      <h3>{box.Title}</h3>
                                      <ReactMarkdown
                                        source={box.Description}
                                        escapeHtml={false}
                                      />
                                      <div className="val-box-btns">
                                        <a
                                          onClick={() =>
                                            ShowValuationForm(
                                              box.Valuation_Type
                                            )
                                          }
                                          className="btn btn-outline"
                                          href="javascript:;"
                                        >
                                          <span>{box.CTA_Label}</span>
                                        </a>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              )
                            })}
                          </Row>
                        </Container>
                      </ScrollAnimation>
                    )}
                </>
              )
            })}
          <Container>
            <Row>
              <Col xl={8} className="tab-column">
                {Valuationform && (
                  <div className="valuationform">
                    <ValuationForm valuationtype={valtype} to_email_cc={`marketing@locationlocation.com`}/>
                  </div>
                )}
                <div className="d-md-none">
                  <GoogleReviewValuationStep />
                </div>
              </Col>
              {/* <Col
                xl={4}
                className="tab-column instant-all-set instantvaluationsection"
              >
                <div className="imready">
                  <h4>Are you ready for a face-to-face valuation?</h4>
                  <p>
                    Book a face-to-face valuation with one of our experts on
                    your preferred date and time.
                  </p>
                  <button className="btn btn-outline instantdateclick">
                    I'm all set
                  </button>
                 
                </div>
              </Col> */}
            </Row>
            <Row className="d-none d-md-block">
              <Col xl={12} className="p-0">
                <GoogleReviewValuationStep />
              </Col>
            </Row>
          </Container>
        </div>
        <div class="overlay-valuation-bg"></div>
      </section>
    </React.Fragment>
  )
}
export default ValuationStepsComponent
