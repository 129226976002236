import React, { useState, useEffect } from "react"
import "./TailBlock.scss"
import HTMLReactParser from "html-react-parser"
import { isMobile, isMobileOnly } from "react-device-detect"
import wordsToNumbers from "words-to-numbers"
import GetCTA from "../getcta"
import ScrollAnimation from "react-animate-on-scroll"
import getVideoId from "get-video-id"
import { Container, Row, Col } from "react-bootstrap"
import GoogleReviewImg from "../../images/google-reviews-1.png"
import axios from "axios"
import PlayVideo from "../../Components/Play/PlayVideo"
import { useLocation } from "@reach/router"

const Tailblock = props => {
  const [testimonials, setTestimonials] = useState([])
  const videoid = props.Module.Video_Url && getVideoId(props.Module.Video_Url)
  const [isPlay, setPlay] = useState(false)
  const [show, setShow] = useState(true)
  const location = useLocation();
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        },
      }) // could be from env files
      setTestimonials(data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url)

    if (isMobileOnly && props.Module.Show_Review) {
      console.log("yes")
      setShow(false)
    }
  }, [])

  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count

  return (
    <div
      className={` tailBlock_wrapper ${
        props.Module.Bg_color === "dark" && "bgdark"
      } ${
        props.Module.Show_Review === true
          ? "top-block-banner"
          : "normal-block-banner"
      }  
      ${
        props.Module.Show_Review === false &&
        props.Module.Bg_color === "white" &&
        "bglightWhite"
      }
      
      
      `}
    >
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
        <Container
          className={`tailBlock_container ${
            props.Module.Media_Position === "left"
              ? "position-left"
              : "position-right"
          }  `}
        >
          <div className={`${location.pathname === "/property-services/landlords/" ? "block_content_landlord" : "" } block_content`}>
            <div className="content top-text">
              {props.Module.Content
                ? HTMLReactParser(props.Module.Content)
                : null}
            </div>
            {!show && (
              <div className="block_media">
                <img src={props.Module.Tile_Image.url} alt="" />

                {props.Module.Video_Url && (
                  <a
                    href="javascript:;"
                    className="video-arrow"
                    onClick={e => {
                      setPlay(true)
                    }}
                  >
                    <i class="icon-video-white"></i>
                  </a>
                )}

                {props.Module.Video_Url != null &&
                  props.Module.Video_Url != "" && (
                    <PlayVideo
                      isOpen={isPlay}
                      isCloseFunction={setPlay}
                      videoId={props.Module.Video_Url}
                      isAutoPlay={1}
                    />
                  )}
              </div>
            )}

            {!show && (
              <div className={`${location.pathname === "/property-services/landlords/" ? "below_block_content_landlord" : "" } content below-text`}>
                {props.Module.Content
                  ? HTMLReactParser(props.Module.Content)
                  : null}
              </div>
            )}

            <div className="block_btn">
              <GetCTA
                link={
                  props.Module.Cta_Link && props.Module.Cta_Link.Primary_URL
                }
                class={`${
                  props.Module.Bg_color === "dark"
                    ? "btn btn-outline yellow-btn"
                    : props.Module.Show_Review
                    ? "btn btn-outline yellow-btn"
                    : "btn btn-outline"
                }
                ${
                  props.Module.Show_Review === false &&
                  props.Module.Bg_color === "white" &&
                  props.Module.Media_Position === "right" &&
                  "bglightWhite"
                }`}
                Label={props.Module.Cta_Label}
              />
            </div>
            {props.Module.Show_Review === true && (
              <div className="block_google_review">
                <div className="google-rating-wrap">
                  <img
                    src={GoogleReviewImg}
                    alt="Reviews - Location Location"
                  />
                  {testimonials && testimonials.length > 0 && (
                    <div className="google-inner">
                      <span>
                        Rated{" "}
                        <strong> {parseFloat(rating_avg).toFixed(1)}/5 </strong>{" "}
                        from <strong>{testimonial_count} Reviews</strong>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {show && (
            <div className="block_media">
              <img src={props.Module.Tile_Image.url} alt="" />

              {props.Module.Video_Url && (
                <a
                  href="javascript:;"
                  className="video-arrow"
                  onClick={e => {
                    setPlay(true)
                  }}
                >
                  <i class="icon-video-white"></i>
                </a>
              )}

              {props.Module.Video_Url != null &&
                props.Module.Video_Url != "" && (
                  <PlayVideo
                    isOpen={isPlay}
                    isCloseFunction={setPlay}
                    videoId={props.Module.Video_Url}
                    isAutoPlay={1}
                  />
                )}
            </div>
          )}
        </Container>
      </ScrollAnimation>
    </div>
  )
}

export default Tailblock
